<template>
    <v-main>
        <div class="pt-9 pb-9 pr-9 pl-9">
            <v-container>
                <v-row class="mb-9">
                    <Tabs
                        ref="tabEl"
                        @update="updateTab"
                        :tabs="tabs"
                        :allowUserChange="true"
                        :showCounter="false"
                    />
                </v-row>
                <OpenOutgoes
                    v-show="currentTab === 'lista'"
                    class="mb-3"
                    :routeType="routeType"
                    @onConfirmEligibles="onConfirmEligibles"
                />

                <v-row class="mt-16" v-if="currentTab === 'lista'">
                    <ListaRemessas :outgoesFilterType="outgoesFilterType" />
                </v-row>
                <v-row class="mt-16" v-if="currentTab === 'desembolsos'">
                    <Desembolsos :routeType="routeType" :outgoesFilterType="outgoesFilterType" />
                </v-row>
            </v-container>
        </div>
        <Loader
            v-if="
                cancelOutgoRequestStatus === RequestStatusEnum.START ||
                sendOutgoRequestStatus === RequestStatusEnum.START
            "
            mode="overlay"
        />
    </v-main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Tabs from '@/components/base/Tabs';
import ListaRemessas from './_ListaRemessas';
import Desembolsos from './_Desembolsos';
import OpenOutgoes from '../components/_OpenOutgoes';
import { RequestStatusEnum } from '@/helpers/consts';
import { notification } from '@/helpers/notification';
import Loader from '@/components/base/Loader';
export default {
    components: {
        Tabs,
        Desembolsos,
        ListaRemessas,
        OpenOutgoes,
        Loader,
    },
    data() {
        return {
            tabs: [
                {
                    name: this.$tc('delivery.label'),
                    path: 'lista',
                },
                {
                    name: this.$tc('outgo.label'),
                    path: 'desembolsos',
                },
            ],
            currentTab: null,
            RequestStatusEnum,
        };
    },
    computed: {
        ...mapGetters('gestao', {
            selectedProduct: 'selectedProduct',
            openOutgoesListRequestStatus: 'openOutgoesListRequestStatus',
            removeNFsByIdAndClientRequestStatus: 'removeNFsByIdAndClientRequestStatus',
            sendOutgoRequestStatus: 'sendOutgoRequestStatus',
            cancelOutgoRequestStatus: 'cancelOutgoRequestStatus',
        }),
        tabFromQuery() {
            return this.$router.history.current.params.step;
        },
        outgoesFilterType() {
            return this.$route.meta.filters.tipo;
        },
        routeType() {
            return this.$route.meta.type;
        },
    },
    created() {
        this.dismissOpenOutgoes();
    },
    mounted() {
        this.currentTab = this.tabFromQuery;
        const index = this.tabs.findIndex(tab => this.currentTab === tab.path);
        if (this.$refs.tabEl.toIndex) {
            this.$refs.tabEl.toIndex(index);
        }
        this.updateOpenOutgoes();
    },
    methods: {
        updateTab(item) {
            this.$refs.tabEl.updateTabRouting(this, item, '/admin/gestao/factoring');
        },
        updateOpenOutgoes() {
            this.fetchOpenOutgoes({
                idProduct: this.selectedProduct.id,
                status: '1,2',
                filters: { tipo: this.outgoesFilterType },
            });
        },
        ...mapActions('gestao', {
            fetchOpenOutgoes: 'fetchOpenOutgoes',
            dismissOpenOutgoes: 'dismissOpenOutgoes',
            removeNFsByIdAndClients: 'removeNFsByIdAndClients',
        }),
        onConfirmEligibles(outgoId, meta) {
            this.removeNFsByIdAndClients({
                idProduct: this.selectedProduct.id,
                outgoId,
                clientIds: meta.removedItems,
                nfIds: meta.removedNFs,
            });
        },
    },
    watch: {
        removeNFsByIdAndClientRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                this.updateOpenOutgoes();
                notification(
                    this.$dialog.notify.success,
                    this.$tc('delivery.removeNFsByIdAndClient.success')
                );
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('delivery.removeNFsByIdAndClient.error', { msg: newValue.message })
                );
                return RequestStatusEnum.ERROR;
            }
        },
        cancelOutgoRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                this.updateOpenOutgoes();
                notification(this.$dialog.notify.success, this.$tc('outgo.cancel.success'));
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('outgo.cancel.error', { msg: newValue.message })
                );
                return RequestStatusEnum.ERROR;
            }
        },
        sendOutgoRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                this.updateOpenOutgoes();
                notification(this.$dialog.notify.success, this.$tc('outgo.send.success'));
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    this.$t('outgo.send.error', { msg: newValue.message })
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },
};
</script>
