export default [
    {
        text: 'Cliente',
        sortable: false,
        value: 'name',
        align: 'left',
    },
    {
        text: 'Valor',
        sortable: false,
        value: 'value',
        align: 'center',
        width: 176,
    },
    {
        text: 'Número',
        sortable: false,
        value: 'number',
        align: 'center',
        width: 176,
    },
    {
        text: 'Vencimento',
        sortable: false,
        value: 'dueDate',
        align: 'center',
        width: 160,
    },
    {
        text: 'Emissão',
        sortable: false,
        value: 'issuedAt',
        align: 'center',
        width: 160,
    },
    {
        text: 'Criação',
        sortable: false,
        value: 'createdAt',
        align: 'center',
        width: 160,
    },
];
